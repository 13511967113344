import ServicesTemplate from "../../components/ServicesTemplate";
import { ServicesProps } from "../../service";

const config: ServicesProps = {
  title: "Self-Managed Cloud Hosting",
  sectionHeader:
    "Manage your own cloud hosting, with a 99.99% Uptime SLA and the fastest support in the industry",
  sectionContent:
    "goSystem's Self-Managed Cloud Hosting makes your life simple. With our intuitive interface, you can instantly scale your services to fit your needs. Our 24/7 incident response team make downtime a thing of the past, responding to any issue on our network within just 3 minuets.\n\nWe partner with some of the biggest names in the business as well as provide our own hosting in our datacentre to provide the best possible experience for all of our customers.",
  callToAction: "Start your cloud journey with goSystem today",
};

export default () => ServicesTemplate(config);
